import * as z from "zod";

import {
  ActionType,
  DecisionType,
  FlowType,
  OptionSelectionType,
  ResultType,
  ValueType,
} from "@/graphql/generated/graphql";

import { actionSchema } from "./action";
import { fieldSetSchema } from "./fields";
import { permissionSchema } from "./permission";
import { resultsSchema } from "./result";

export type FlowSchemaType = z.infer<typeof flowSchema>;
export type StepSchemaType = z.infer<typeof stepSchema>;
export type ReusableSchema = z.infer<typeof reusableSchema>;
export type FlowWithEvolveFlowSchemaType = z.infer<typeof flowWithEvolveFlowSchema>;

const stepSchema = z
  .object({
    stepId: z.string().uuid(),
    fieldSet: fieldSetSchema,
    response: z
      .object({
        permission: permissionSchema,
        allowMultipleResponses: z.boolean().default(false),
        canBeManuallyEnded: z.boolean().default(false),
        expirationSeconds: z.coerce.number().int().positive(),
        minResponses: z.coerce.number().int().positive().default(1),
      })
      .optional(),
    result: resultsSchema,
    action: actionSchema.optional(),
  })
  .superRefine((step, ctx) => {
    step.result.forEach((res, index) => {
      if (res.type === ResultType.Decision) {
        const field = step.fieldSet.fields.find((f) => {
          return f.fieldId === res.fieldId;
        });
        const defaultOptionId = res.decision.defaultDecision?.optionId;

        if (field?.type !== ValueType.OptionSelections) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Decision must have an options field",
            path: ["result", index],
          });
          return;
        }

        if (
          field.optionsConfig.selectionType === OptionSelectionType.Rank &&
          res.decision.type !== DecisionType.WeightedAverage
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Ranked field must have a weighted average decision",
            path: ["result", index],
          });
        }

        if (
          field.optionsConfig.selectionType === OptionSelectionType.None &&
          res.decision.type !== DecisionType.Ai
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Ai decision cannot be selectable",
            path: ["result", index],
          });
        }

        if (defaultOptionId) {
          const defaultOption = field.optionsConfig.options.find(
            (o) => o.optionId === defaultOptionId,
          );
          if (!defaultOption) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Default option must be a valid option",
              path: ["result", index, "decision", "defaultDecision", "optionId"],
            });
          }
        }

        if (res.decision.conditions.length > 0) {
          res.decision.conditions.forEach((condition, conditionIndex) => {
            const isValidOption = field.optionsConfig.options.some(
              (option) => option.optionId === condition.optionId,
            );
            if (!isValidOption) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Not a valid option",
                path: ["result", index, "decision", "conditions", conditionIndex, "optionId"],
              });
            }
          });
        }
      }
    });
  })
  // check that action filter is valid
  .superRefine((step, ctx) => {
    if (step.action?.filter) {
      const { resultConfigId, optionId } = step.action.filter;
      const resultConfigFieldId = step.result.find(
        (r) => r.resultConfigId === resultConfigId,
      )?.fieldId;
      if (!resultConfigFieldId) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Default option must be a valid option",
          path: ["action", "filter", "resultConfigId"],
        });
        return;
      }
      const field = step.fieldSet.fields.find((f) => f.fieldId === resultConfigFieldId);
      const foundOption =
        field?.type === ValueType.OptionSelections &&
        field.optionsConfig.options.some((option) => option.optionId === optionId);
      if (!foundOption) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Default option must be a valid option",
          path: ["action", "filter", "optionId"],
        });
      }
    }
  })

  .refine(
    (step) => {
      if (step.response && step.fieldSet.fields.length === 0) return false;
      else return true;
    },
    {
      message: "Add a result to this step",
    },
  );

export const flowSchema = z
  .object({
    flowVersionId: z.string().uuid(),
    type: z.nativeEnum(FlowType),
    name: z.string().min(1, "Enter a name"),
    steps: z.array(stepSchema).min(1, "There must be at least 1 step"),
    // evolve: evolveFlowSchema,
    fieldSet: fieldSetSchema,
    trigger: z.object({
      permission: permissionSchema,
    }),
    requestName: z.string().optional(),
  })
  .refine(
    (flow) => {
      if (flow.steps.length === 1 && flow.steps[0].result.length === 0 && !flow.steps[0].action) {
        return false;
      } else return true;
    },
    { message: "There must be at least one collaborative step or action", path: ["steps"] },
  ) // check if linked result options are valid
  .superRefine((flow, ctx) => {
    flow.steps.forEach((step, stepIndex) => {
      step.fieldSet.fields.forEach((field, fieldIndex) => {
        if (
          field.type === ValueType.OptionSelections &&
          field.optionsConfig.linkedResultOptions.length > 0
        ) {
          field.optionsConfig.linkedResultOptions.forEach(
            (linkedResultOption, linkedResultIndex) => {
              let hasMatch = false;
              const precedingSteps = flow.steps.slice(0, stepIndex);
              for (const s of precedingSteps) {
                if (s.result.find((r) => r.resultConfigId === linkedResultOption.id)) {
                  hasMatch = true;
                  break;
                }
              }
              if (!hasMatch) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: "Linked result not found",
                  path: [
                    "steps",
                    stepIndex,
                    "fieldSet",
                    "fields",
                    fieldIndex,
                    "optionsConfig",
                    "linkedResultOptions",
                    linkedResultIndex,
                    "id",
                  ],
                });
              }
            },
          );
        }
      });
    });
  })
  .superRefine((flow, ctx) => {
    const checkStepAction = (step: StepSchemaType, index: number) => {
      if (step.action?.type === ActionType.TriggerStep && step.action.stepId) {
        const nextStep = flow.steps[index + 1];
        if (!nextStep) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "This step as a trigger action but there is no step to trigger",
            path: ["steps", index],
          });
        }
        if (nextStep.stepId !== step.action.stepId) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "This step incorrectly points to a different step",
            path: ["steps", index],
          });
        }
        checkStepAction(nextStep, index + 1);
      }
    };
    checkStepAction(flow.steps[0], 0);
  });

export const reusableSchema = z.object({
  reusable: z.boolean(),
});

export const flowWithEvolveFlowSchema = z.object({
  reusable: z.boolean(),
  flow: flowSchema,
  evolve: flowSchema.optional(),
});

export const newFlowFormSchema = z.object({
  new: flowWithEvolveFlowSchema,
});
